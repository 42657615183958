var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"gloss-src src-num"},[_vm._v(_vm._s(_vm.gloss.num))]),('audio_url' in _vm.gloss)?[_c('button',{staticClass:"iu-audio",attrs:{"title":"IU發音"},on:{"click":function($event){return _vm.playAudio(_vm.gloss.audio_url)}}},[_c('v-icon',{attrs:{"small":"","color":"blue lighten-2"}},[_vm._v("mdi-volume-high")])],1)]:('video' in _vm.gloss.meta & _vm.gloss.meta.video != 'None')?[((_vm.gloss.iu_a_span[0] != null) & (_vm.gloss.iu_a_span[1] != null))?_c('button',{staticClass:"iu-audio",attrs:{"title":"IU發音"},on:{"click":function($event){_vm.playAudio(
          _vm.get_audio_url_by_split_time(
            _vm.gloss.iu_a_span[0],
            _vm.gloss.iu_a_span[1],
            _vm.gloss.meta.video
          )
        )}}},[_c('v-icon',{attrs:{"small":"","color":"blue lighten-1"}},[_vm._v("mdi-volume-high")])],1):_vm._e()]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(255, 115, 133, 0.85)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"gloss-src src-doc"},'span',attrs,false),on),[_c('router-link',{attrs:{"to":{
            name: 'LongText',
            params: { id: _vm.gloss.file },
            hash: '#' + _vm.gloss.num,
          }}},[_c('v-icon',{attrs:{"dense":"","color":"pink lighten-3"}},[_vm._v("mdi-link-box-variant-outline")])],1)],1)]}}])},[_vm._l((_vm.gloss.meta),function(value,name){return [((value != '') & (value != 'None'))?_c('span',{key:value + name,staticClass:"meta"},[_c('span',{staticStyle:{"display":"inline-block","min-width":"5.5em"}},[_vm._v(_vm._s(name)+":")]),_c('strong',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(value))]),_c('br')]):_vm._e()]})],2),_c('div',{staticClass:"example gloss--glossed"},[(_vm.gloss.ori.length > 0)?_c('p',{staticClass:"gloss__line--original gloss__line gloss__line--0"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.ori_highlighted)}})]):_vm._e(),_c('div',{staticClass:"gloss__words"},_vm._l((_vm.gloss_hightlighted),function(tup,idx){return _c('div',{key:idx + Math.random(),staticClass:"gloss__word"},[_c('p',{staticClass:"gloss__line gloss__line--1"},[_c('span',{domProps:{"innerHTML":_vm._s(tup[0])}})]),_c('p',{staticClass:"gloss__line gloss__line--2"},[(tup[1] == '_')?_c('span'):_c('span',{domProps:{"innerHTML":_vm._s(tup[1])}})]),_c('p',{staticClass:"gloss__line gloss__line--3"},[(tup[2] == '_')?_c('span'):_c('span',{domProps:{"innerHTML":_vm._s(tup[2])}})])])}),0),_vm._l((_vm.free_highlighted),function(line,i){return _c('p',{key:i + Math.random(),class:("gloss__line--free gloss__line gloss__line--" + (i + 4))},[_c('span',{domProps:{"innerHTML":_vm._s(line)}})])})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }